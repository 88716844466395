<template>
  <div class="modal-card" data-test-id="vmc-modal">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-primary">Vehicle Mileage Check</p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="$modal.close">Cancel</a>
          </div>
        </div>
        <VmcOptions v-if="!manualOpen" @confirm="confirm" />
        <VmcInput v-else :data="data" @check-mileage="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import { node } from '@/plugins/axios'
import * as autocheck from 'modules/autocheck/services'
export default {
  name: 'VmcContainer',
  components: {
    VmcOptions: () => import('./components/VmcOptions'),
    VmcInput: () => import('./components/VmcInput')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    manualOpen: false,
    type: '',
    franchiseId: null
  }),
  methods: {
    confirm({ selected, type }) {
      this.track(type)
      this.type = type
      this.franchiseId = selected.id

      type === 'rapid' ? this.submit(this.data) : (this.manualOpen = true)
    },
    async verifyInput({ vin, vrm }) {
      if (this.type === 'manual' && ((vrm && vin) || !vrm)) {
        try {
          const data = await autocheck.precheck({ value: vin, type: 'vin' })

          if (vrm && data.vrm !== vrm) {
            this.$notify('VRM & VIN do not match the same vehicle')
            return {}
          }

          return { vin: data.vin, vrm: data.vrm }
        } catch {
          this.$notify('VIN not recognised')
          return {}
        }
      }
      return { vin, vrm }
    },
    openVmcTab(token) {
      const aTag = document.createElement('a')
      aTag.rel = 'noopener'
      aTag.target = '_blank'
      aTag.href = `${process.env.VUE_APP_VMC_URL}?token=${token}`
      aTag.click()
      aTag.remove()
    },
    async submit(input) {
      const { type, franchiseId } = this
      this.$wait.start(`${type}Loading`)

      try {
        const { vrm, vin } = await this.verifyInput(input)
        if (type === 'manual' && !vrm) return

        const params = {
          franchiseId,
          vin: vin || 'VIN',
          vrm: vrm || 'VRM',
          mileage: input.mileage || 0,
          entryPoint: type
        }

        const { data } = await node.post('/autocheck/vmc', params)
        this.openVmcTab(data.token)
        this.$modal.close()
      } catch {
        this.$notify('VMC currently unavailable')
      } finally {
        this.$wait.end(`${type}Loading`)
      }
    },
    track(type) {
      this.$mxp.track(`vmc_select_${type}_input`)
    }
  }
}
</script>
